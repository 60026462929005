import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { getPurchasedDiamondByUserList } from "../../../../redux/slices/adminService";
import PageIndex from "../../../PageIndex";
import moment from "moment/moment";
import { debounce } from "lodash";
import numeral from "numeral";
import FileExcel from "../../../../component/common/Exportexcel";
import dayjs from "dayjs";

export default function DiamondTickUserList() {
  const location = PageIndex.useLocation();
  const navigate = PageIndex.useNavigate();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [diamondTickUsersList, setDiamondTickUsersList] = useState([]);
  const [excel, setExcel] = useState([]);

  const queryParams = new URLSearchParams(window.location.search);
  const stateParam = queryParams.get("state");
  const state = JSON.parse(decodeURIComponent(stateParam));

  const [selectedFromDate, setSelectedFromDate] = useState(
    state?.firstDayOfMonth ? dayjs(state.firstDayOfMonth) : ""
  );
  const [selectedToDate, setSelectedToDate] = useState(
    state?.currentDate ? dayjs(state?.currentDate) : ""
  );
  const handleClearFromDate = () => {
    setSelectedFromDate(null);
    setCurrentPage(1);
    navigate({ state: "" });
  };
  const handleClearToDate = () => {
    setSelectedToDate(null);
    setCurrentPage(1);
    navigate({ state: "" });
  };

  const fetchDiamondTickusersList = () => {
    setLoader(true);
    const data = {
      startDate: selectedFromDate
        ? dayjs(selectedFromDate).format("DD/MM/YYYY")
        : "",
      endDate: selectedToDate ? dayjs(selectedToDate).format("DD/MM/YYYY") : "",
      currentPage: currentPage, search: search,  rowsPerPage: rowsPerPage
    };
    getPurchasedDiamondByUserList(data).then(
      (data) => {
        if (data?.status == 200) {
          setDiamondTickUsersList(data?.data);
          if (data?.currentPage) {
            setCurrentPage(data?.currentPage);
          }
          setPageCount(data?.totalCount ? data?.totalCount : 0);
          if (data?.currentPage) {
            setCurrentPage(data?.currentPage);
          }
          setPageCount(data?.totalCount ? data?.totalCount : 0);
          setLoader(false);
        } else {
          setDiamondTickUsersList([]);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        }
      }
    );
  };

  useEffect(() => {
    const debouncedFetch = debounce(fetchDiamondTickusersList, 500);

    if (search || currentPage || rowsPerPage) {
      debouncedFetch();
    } else {
      fetchDiamondTickusersList();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [search, currentPage, rowsPerPage]);

  useEffect(() => {
    const debouncedFetch = debounce(fetchDiamondTickusersList, 500);

    if (selectedFromDate || selectedToDate) {
      debouncedFetch();
    } else if (selectedFromDate == null || selectedToDate == null) {
      fetchDiamondTickusersList();
    } return () => {
      debouncedFetch.cancel();
    };
  }, [selectedFromDate, selectedToDate]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const fileName = "Crypto_list Report";
  useEffect(() => {
    if (diamondTickUsersList?.length > 0) {
      const customHeadings = diamondTickUsersList?.map((item, index) => ({
        "S.No": index + 1,
        "Crypto Name": item?.tokenName,
        Price: item?.tokenPrice,
        "Price Change % (24hr)": item?.priceChangePercentage,
        Popular: item?.isPopular ? "Popular" : "Non-popular",
      }));

      setExcel(customHeadings);
    }
  }, [diamondTickUsersList]);

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
        <Index.Box className="common-box">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Diamond Tick Users List
              </Index.Typography>
            </Index.Box>
            <Index.Box className="contest-search-list userlist-btn-flex">
              <Index.Box className="contest-search-list-1">
                <>
                  <Index.Box className="admin-text-field-main from-input mb-0">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.Box className="form-group transaction-fieldset">
                        <Index.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <Index.InputLabel
                            className="form-lable"
                            id="demo-simple-label"
                          >
                            From
                          </Index.InputLabel>
                          <Index.Stack className="date-picker-mui cus-datepicker">
                            {/* From Date */}
                            <Index.MobileDatePicker
                              className="form-control datepicker"
                              format="DD-MM-YYYY"
                              value={selectedFromDate}
                              disableFuture
                              // maxDate={selectedToDate}
                              onChange={(newValue) => {
                                setSelectedFromDate(newValue);
                                setCurrentPage(1);
                              }}
                            />
                            {selectedFromDate ? (
                              <Index.IconButton
                                className="clear-icon"
                                onClick={handleClearFromDate}
                              >
                                <Index.ClearIcon className="icon-clear" />
                              </Index.IconButton>
                            ) : (
                              ""
                            )}
                          </Index.Stack>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-text-field-main from-input mb-0">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.Box className="form-group transaction-fieldset">
                        <Index.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <Index.InputLabel
                            className="form-lable"
                            id="demo-simple-label"
                          >
                            To
                          </Index.InputLabel>
                          <Index.Stack className="sale-field date-picker-mui cus-datepicker">
                            {/* To Date */}
                            <Index.MobileDatePicker
                              className=" form-control datepicker"
                              format="DD-MM-YYYY"
                              value={selectedToDate}
                              // disableFuture
                              // minDate={selectedFromDate}
                              onChange={(newValue) => {
                                setSelectedToDate(newValue);
                                setCurrentPage(1);
                              }}
                            />
                            {selectedToDate ? (
                              <Index.IconButton
                                className="clear-icon"
                                onClick={handleClearToDate}
                              >
                                <Index.ClearIcon className="icon-clear" />
                              </Index.IconButton>
                            ) : (
                              ""
                            )}
                          </Index.Stack>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </>
              </Index.Box>
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth_123"
                      type="text"
                      value={search}
                      autoComplete="search"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/^\s+/, "")
                          .replace(/\s\s+/g, " ");
                        setSearch(newValue);
                        setCurrentPage(1);
                        // getCryptoList(newValue);
                      }}
                    />
                    <span className="search-icon-box">
                      <img src={Index.Svg.search} className="search-icon" />
                    </span>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box>
                <FileExcel apiData={excel} fileName={fileName} />
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-userlist"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="cryptolist-table-main action-column page-table-main">
                      {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}

                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                        // sx={{ maxHeight:400 }}
                      >
                        <Index.Table
                          stickyHeader
                          aria-label="sticky table"
                          sx={{ minWidth: 650 }}
                          className="table"
                        >
                          <Index.TableHead className="table-head cus-table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                S.No
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Username
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Subscripton Plan
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Currency Type
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Subscription Price
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Purchased Date & Time
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Validity Date
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {!loader ? (
                              diamondTickUsersList?.length > 0 ? (
                                diamondTickUsersList?.map((row, index) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {(currentPage - 1) * 10 + index + 1}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      <span
                                        className=" cus-user-name-navigate"
                                        onClick={() => {
                                          navigate("/dashboard/user-view", {
                                            state: {
                                              data: row?.userId?._id,
                                              pathName: location.pathname,
                                            },
                                          });
                                        }}
                                      >
                                        {row?.userId?.userName
                                          ? row?.userId?.userName
                                          : "-"}
                                      </span>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.planType ? row?.planType : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.userCurrencyType
                                        ? row?.userCurrencyType
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {/* {row?.planAmount ? row?.planAmount : "-"} */}
                                      {row?.planAmount
                                        ? parseFloat(
                                            row?.planAmount?.toFixed(4)
                                          )
                                        : 0}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.createdAt
                                        ? Index.moment(row?.createdAt).format(
                                            "DD/MM/YYYY HH:MM "
                                          )
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.validityDate
                                        ? row?.validityDate
                                        : "-"}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <PageIndex.RecordNotFound colSpan={7} />
                              )
                            ) : (
                              <PageIndex.TableLoader colSpan={7} />
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                      {/* </Paper> */}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          </Index.Box>

          {diamondTickUsersList.length ? (
            <Index.Box className="pagination-main">
              <Index.TablePagination
                rowsPerPageOptions={[
                  { label: "All", value: -1 },
                  10,
                  25,
                  50,
                  75,
                  100,
                ]}
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={currentPage - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
                variant="outlined"
                shape="rounded"
                className="pagination"
              />
            </Index.Box>
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>

      {/* Delete Modal */}
    </>
  );
}
