import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import CommonTable from "../../../../component/common/CommonTable";
import { debounce } from "lodash";
import {
  DepositeMoneyIntoWallet,
  getAssestsList,
  getCurrencyListAction,
  getFiatQR,
  getUserTransactionList,
  getUserWalletDataForAdmin,
} from "../../../../redux/slices/adminService";
import dayjs from "dayjs";
import numeral from "numeral";

const typeList = [
  { id: 1, value: "All" },
  { id: 2, value: "Success" },
  { id: 3, value: "Pending" },
  { id: 4, value: "Failed" },
];
const ViewFiatWallet = () => {
  const params = PageIndex.useLocation();
  const navigate = PageIndex.useNavigate();
  const userId = params?.state?.data;
  const TypeOfWalletCurrency = params?.state?.currencyData
    ? params?.state?.currencyData
    : params?.state?.TypeOfCurrency;

  const [transactionList, setTransactionList] = useState([]);
  const [fiatQR, setFiatQR] = useState("");
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(true);
  const [qrLoader, setQrLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [userWalletDetails, setUserWalletDetails] = useState("");
  const [type, setType] = useState("All");
  const [open, setOpen] = useState(false);
  const [openAddMoney, setOpenAddMoney] = useState(false);
  const [singleTransactionData, setSingleTransaction] = useState("");
  const [assestsList, setAssestsList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);

  const handleOpen = (data) => {
    setOpen(true);
    setSingleTransaction(data);
  };
  const handleClose = () => setOpen(false);

  const initialValues = {
    userId: userId,
    amount: "",
    currencyType: TypeOfWalletCurrency,
    currency: "",
  };

  const handleOpenAddMoney = (initialValues, isViewCome = false) => {
    // setSingleCrypto(data);
    // setIsView(isViewCome);
    setOpenAddMoney(true);
  };
  const handleCloseAddMoney = () => {
    setOpenAddMoney(false);
    // setIsView(false);
  };

  const fetchAssestList = (page) => {
    setLoading(true);

    getAssestsList(userId).then((data) => {
      if (data?.status == 200) {
        setAssestsList(data?.data);
        setLoading(false);
      } else {
        setAssestsList([]);
        setLoading(false);
      }
    });
  };
  const getcurrencyType = (data) => {
    getCurrencyListAction(data).then((res) => {
      setCurrencyList(res?.data);
    });
  };

  useEffect(() => {
    getcurrencyType(TypeOfWalletCurrency);
  }, [TypeOfWalletCurrency]);

  useEffect(() => {
    fetchAssestList();
  }, []);

  //api
  const columns = [
    "S.No",
    "Date",
    "Transaction Id",
    "Transaction Type",
    "Amount",
    "Status",
    "Actions",
  ];

  const columnMap = {
    "S.No": "sNo",
    Date: "Date",
    "Transaction Id": "transactionId",
    "Transaction Type": "type",
    Amount: "amount",
    Status: "paymentStatus",
    Actions: "actions",
  };
  const sortableColumns = ["Date", "Transaction Type"];

  const fetchUserWalletDetails = (page = currentPage) => {
    setLoading(true);

    getUserWalletDataForAdmin(userId).then((data) => {
      if (data?.status == 200) {
        setUserWalletDetails(data?.data);
        setLoading(false);
      } else {
        setUserWalletDetails([]);
        setLoading(false);
      }
    });
  };
  const fetchQR = (page) => {
    setQrLoader(true);

    getFiatQR(userId).then((data) => {
      if (data?.status == 200) {
        setFiatQR(data?.data?.qrImageUrl?.url?.qrImageUrl);
        setQrLoader(false);
      } else {
        setFiatQR("");
        setQrLoader(false);
      }
    });
  };

  const fetchUserTransactionList = (page) => {
    setLoader(true);
    let obj = {};
    if (selectedFromDate || selectedToDate) {
      obj = {
        startDate: selectedFromDate
          ? dayjs(selectedFromDate).format("DD/MM/YYYY")
          : "",
        endDate: selectedToDate
          ? dayjs(selectedToDate).format("DD/MM/YYYY")
          : "",
      };
    }
    let queryPage = page || currentPage || 1;
    let previousPage = queryPage;

    getUserTransactionList({
      obj,
      userId,
      page: queryPage,
      search,
      type: "fiat",
      status: type,
    }).then((data) => {
      if (data?.status == 200) {
        if (data?.data.length === 0) {
          setCurrentPage(previousPage);
          setTransactionList([]);
        } else {
          const formattedData = data?.data.map((item) => ({
            ...item,
            Date: Index.moment(item.createdAt).format("DD/MM/YYYY hh:mm A"),
          }));
          setTransactionList(formattedData);
          if (data?.currentPage) {
            setCurrentPage(data?.currentPage);
          }
          setPageCount(data?.totalPages);
        }
        setLoader(false);
      } else {
        setUserWalletDetails([]);
        setLoader(false);
      }
    });
  };
  const handleType = (e) => {
    setType(e.target.value);
    setCurrentPage(1);
  };
  useEffect(() => {
    const debouncedFetch = debounce(fetchUserTransactionList, 300);

    if (search) {
      debouncedFetch();
    } else {
      fetchUserTransactionList();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [search, type]);

  useEffect(() => {
    const debouncedFetch = debounce(fetchUserTransactionList, 300);
    if (currentPage !== undefined) {
      debouncedFetch();
    }
  }, [currentPage]);

  useEffect(() => {
    const debouncedFetch = debounce(fetchUserTransactionList, 300);

    if (selectedFromDate || selectedToDate) {
      debouncedFetch();
    } else if (selectedFromDate == null || selectedToDate == null) {
      fetchUserTransactionList();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [selectedFromDate, selectedToDate]);
  useEffect(() => {
    fetchUserWalletDetails();
  }, []);

  const handleClearFromDate = () => {
    setSelectedFromDate(null);
  };
  const handleClearToDate = () => {
    setSelectedToDate(null);
  };
  useEffect(() => {
    fetchQR();
  }, []);

  const handleDepositeMoney = async (values) => {
    setLoader(true);

    const InitialData = {
      userId: userId,
      amount: values?.amount,
      currencyType: TypeOfWalletCurrency,
      currency: values?.currency,
    };

    DepositeMoneyIntoWallet(InitialData).then((data) => {
      if (data?.status == 200) {
        handleCloseAddMoney();
        fetchUserWalletDetails();
        fetchUserTransactionList();
        setLoader(false);
      } else {
        handleCloseAddMoney();
      }
    });
  };

  return (
    <>
      {!loading ? (
        <Index.Box className="dashboard-content">
          <PageIndex.BackButton
            onClick={() =>
              navigate(
                params?.state?.pathName
                  ? params?.state?.pathName
                  : "/dashboard/wallet-management",
                {
                  state: {
                    postKey: 0,
                    data: params?.state?.data,
                    pathName: params?.state?.pathName,
                  },
                }
              )
            }
          />
          <Index.Box className="barge-common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  User Basic Details
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 0, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box user-details-dash-box">
                      <Index.Box
                        sx={{ width: 1 }}
                        className="grid-main common-card"
                      >
                        <Index.Box
                          display="grid"
                          className="display-row"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                              xl: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-details-main">
                              <Index.Box className="user-details-flex">
                                <Index.Typography className="user-details-lable">
                                  Name
                                </Index.Typography>
                                <Index.Typography className="user-details-data">
                                  {userWalletDetails?.userId?.name
                                    ? userWalletDetails?.userId?.name
                                    : "-"}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                              xl: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-details-main">
                              <Index.Box className="user-details-flex">
                                <Index.Typography className="user-details-lable">
                                  Username
                                </Index.Typography>
                                <Index.Typography className="user-details-data ">
                                  <span
                                    className=" cus-user-name-navigate"
                                    onClick={() => {
                                      navigate("/dashboard/user-view", {
                                        state: {
                                          data: userId,
                                          pathName: params.pathname,
                                        },
                                      });
                                    }}
                                  >
                                    {userWalletDetails?.userId?.userName
                                      ? userWalletDetails?.userId?.userName
                                      : "-"}
                                  </span>
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                              xl: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-details-main">
                              <Index.Box className="user-details-flex">
                                <Index.Typography className="user-details-lable">
                                  Status
                                </Index.Typography>
                                <Index.Typography className="user-details-data">
                                  {userWalletDetails?.isActive
                                    ? "Active"
                                    : "Deactive"}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                              xl: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-details-main">
                              <Index.Box className="user-details-flex">
                                <Index.Typography className="user-details-lable">
                                  Currency
                                </Index.Typography>
                                <Index.Typography className="user-details-data">
                                  {userWalletDetails?.currency?.coins
                                    ? userWalletDetails?.currency?.coins
                                    : "-"}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                              xl: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-details-main">
                              <Index.Box className="user-details-flex">
                                <Index.Typography className="user-details-lable">
                                  Balance
                                </Index.Typography>
                                <Index.Typography className="user-details-data">
                                  {userWalletDetails?.fiatBalance
                                    ? parseFloat(
                                        userWalletDetails?.fiatBalance?.toFixed(
                                          4
                                        )
                                      )
                                    : 0}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                              xl: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-details-main">
                              <Index.Box className="user-details-flex">
                                <Index.Typography className="user-details-lable">
                                  Email
                                </Index.Typography>
                                <Index.Typography className="user-details-data">
                                  {userWalletDetails?.userId?.email
                                    ? userWalletDetails?.userId?.email
                                    : "-"}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                              xl: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-details-main">
                              <Index.Box className="user-details-flex">
                                <Index.Typography className="user-details-lable">
                                  QR Code
                                </Index.Typography>
                                <Index.Typography className="user-details-data">
                                  <Index.Box className="qrscanner-box-main">
                                    {fiatQR ? (
                                      <img
                                        src={fiatQR}
                                        // src={PageIndex.Png.QRScanner}
                                        className="qrscanner-img"
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </Index.Box>
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="campaigns-section-main">
              <Index.Box className="user-list-flex">
                <Index.Box className="admin-page-title-main">
                  <Index.Typography
                    className="admin-page-title"
                    component="h2"
                    variant="h2"
                  >
                    Transaction History
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="contest-search-list user-list-flex">
                {/* <Index.Box className="userlist-btn-flex"> */}
                <Index.Box className="contest-search-list-1">
                  {" "}
                  <Index.Box className="admin-text-field-main from-input mb-0">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.Box className="form-group transaction-fieldset">
                        <Index.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <Index.InputLabel id="demo-simple-label">
                            From
                          </Index.InputLabel>
                          <Index.Stack className="date-picker-mui cus-datepicker">
                            {/* From Date */}
                            <Index.MobileDatePicker
                              className="form-control datepicker"
                              format="DD-MM-YYYY"
                              value={selectedFromDate}
                              disableFuture
                              maxDate={selectedToDate}
                              onChange={(newValue) => {
                                setSelectedFromDate(newValue);
                              }}
                            />
                            {selectedFromDate ? (
                              <Index.IconButton
                                className="clear-icon"
                                onClick={handleClearFromDate}
                              >
                                <Index.ClearIcon />
                              </Index.IconButton>
                            ) : (
                              ""
                            )}
                          </Index.Stack>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-text-field-main from-input mb-0">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.Box className="form-group transaction-fieldset">
                        <Index.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <Index.InputLabel id="demo-simple-label">
                            To
                          </Index.InputLabel>
                          <Index.Stack className="sale-field date-picker-mui cus-datepicker">
                            {/* To Date */}
                            <Index.MobileDatePicker
                              className=" form-control datepicker"
                              format="DD-MM-YYYY"
                              value={selectedToDate}
                              disableFuture
                              minDate={selectedFromDate}
                              onChange={(newValue) =>
                                setSelectedToDate(newValue)
                              }
                            />
                            {selectedToDate ? (
                              <Index.IconButton
                                className="clear-icon"
                                onClick={handleClearToDate}
                              >
                                <Index.ClearIcon />
                              </Index.IconButton>
                            ) : (
                              ""
                            )}
                          </Index.Stack>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="contest-search-list-2">
                  <Index.Box className="user-search-main">
                    <Index.Box className="user-search-box">
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder="Search"
                          name="search"
                          value={search}
                          onChange={(e) => {
                            const newValue = e.target.value
                              .replace(/^\s+/, "")
                              .replace(/\s\s+/g, " ");
                            setSearch(newValue);
                          }}
                        />
                        <span className="search-icon-box">
                          <img src={Index.Svg.search} className="search-icon" />
                        </span>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box add-user-input transaction-input-box">
                    <Index.Box className="form-group">
                      <Index.FormControl className="request-dropdown">
                        <Index.Select
                          className="form-control select-drop-list request-drop-list"
                          name="type"
                          value={type}
                          // defaultValue={type ? type : "ALL"}
                          onChange={handleType}
                          displayEmpty
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                        >
                          {typeList &&
                            typeList?.map((val) => (
                              <Index.MenuItem
                                value={val?.value}
                                key={val?.id}
                                className="menuitem"
                              >
                                {val?.value}
                              </Index.MenuItem>
                            ))}
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                {/* </Index.Box> */}
              </Index.Box>
              {/* <Index.Box className="add-money-popup"> */}
              <Index.Box className="adduser-btn-main btn-main-primary add-money-popup">
                {/* {(rolePermission &&
                    rolePermission?.roleType?.rolePermission?.includes(
                      "CryptoList_add"
                    )) ||
                   (rolePermission && rolePermission?.isAdmin === true) ? ( */}
                <>
                  <Index.Button
                    className="adduser-btn btn-primary"
                    onClick={handleOpenAddMoney}
                  >
                    <img
                      src={Index.Svg.plus}
                      className="plus-icon"
                      alt="plus icon"
                    />
                    Add Money
                  </Index.Button>
                </>
                {/* ) : (
                    ""
                   )} */}
              </Index.Box>
              {/* </Index.Box> */}
            </Index.Box>
            <CommonTable
              columnMap={columnMap}
              columns={columns}
              data={transactionList}
              loader={loader}
              setLoader={setLoader}
              pageCount={pageCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setPageCount={setPageCount}
              sortableColumns={sortableColumns}
              handleOpen={handleOpen}
              className={"fiat-wallet-table-main"}
            />
            <PageIndex.ViewTransactionDetailsModel
              open={open}
              handleClose={handleClose}
              singleTransactionData={singleTransactionData}
            />
            <PageIndex.DepositCurrencyModel
              open={openAddMoney}
              initialValues={initialValues}
              handleSubmit={handleDepositeMoney}
              handleClose={handleCloseAddMoney}
              assestsList={assestsList}
              currencyList={currencyList}
            />
          </Index.Box>
        </Index.Box>
      ) : (
        <PageIndex.Loading />
      )}
    </>
  );
};

export default ViewFiatWallet;
