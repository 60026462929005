import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { getContestWinnerList } from "../../../../redux/slices/adminService";
import { debounce } from "lodash";
import dayjs from "dayjs";
import moment from "moment";
import numeral from "numeral";

const ContestWinnerList = () => {
    const navigate = PageIndex.useNavigate();
    const location = PageIndex.useLocation();
    const { rolePermission } = PageIndex.useSelector((state) => state.admin);

    const [contestList, setContestList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [selectedFromDate, setSelectedFromDate] = useState("");
    const [selectedToDate, setSelectedToDate] = useState("");

    function formatDate(originalDateStr) {
        const originalDate = new Date(originalDateStr);
        const day = originalDate.getDate().toString().padStart(2, '0');
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
        const year = originalDate.getFullYear().toString();
        return `${day}/${month}/${year}`;
    }



    const listOfContestParticipants = () => {
        setLoader(true);
        const startDate = selectedFromDate
            ? dayjs(selectedFromDate).format("DD/MM/YYYY")
            : "";
        const endDate = selectedToDate
            ? dayjs(selectedToDate).format("DD/MM/YYYY")
            : "";

        getContestWinnerList(startDate, endDate, currentPage, search, rowsPerPage)
            .then((res) => {
                setLoader(false);
                if (res?.status === 200) {
                    setCurrentPage(res?.currentPage || 1);
                    setPageCount(res?.totalCount ? res?.totalCount : 0);
                    setContestList(res?.data || []);
                } else {
                    setContestList([]);
                }
            })
            .catch(() => {
                setLoader(false);
                setContestList([]);
            });
    };

    // Debounce the function to prevent multiple rapid calls
    const debouncedFetch = debounce(listOfContestParticipants, 300);

    useEffect(() => {
        debouncedFetch();
        return () => {
            debouncedFetch.cancel();
        };
    }, [search, currentPage, selectedFromDate, selectedToDate, rowsPerPage]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value + 1);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
      };

    const handleClearFromDate = () => {
        setSelectedFromDate(null);
    };

    const handleClearToDate = () => {
        setSelectedToDate(null);
    };
    return (
        <>
            <Index.Box className="dashboard-content">
                <Index.Box className="barge-common-box">
                    <Index.Box className="common-box">
                        <Index.Box className="user-list-flex">
                            <Index.Box className="admin-page-title-main">
                                <Index.Typography
                                    className="admin-page-title"
                                    component="h2"
                                    variant="h2"
                                >
                                    Contest Winner List
                                </Index.Typography>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="user-list-flex cus-search-flex ">
                            <Index.Box className="userlist-btn-flex">
                                <Index.Box className="admin-text-field-main from-input mb-0">
                                    <Index.Box className="input-box input-box-admin input-box-admin2">
                                        <Index.Box className="form-group transaction-fieldset">
                                            <Index.LocalizationProvider
                                                dateAdapter={Index.AdapterDayjs}
                                            >
                                                <Index.InputLabel
                                                    className="form-lable"
                                                    id="demo-simple-label"
                                                >
                                                    From
                                                </Index.InputLabel>
                                                <Index.Stack className="date-picker-mui cus-datepicker">
                                                    {/* From Date */}
                                                    <Index.MobileDatePicker
                                                        className="form-control datepicker"
                                                        format="DD-MM-YYYY"
                                                        value={
                                                            selectedFromDate
                                                        }
                                                        onChange={(newValue) =>
                                                            setSelectedFromDate(newValue)
                                                        }
                                                        disableFuture
                                                    />
                                                    {selectedFromDate ? (
                                                        <Index.IconButton
                                                            className="clear-icon"
                                                            onClick={handleClearFromDate}
                                                        >
                                                            <Index.ClearIcon className="icon-clear" />
                                                        </Index.IconButton>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Index.Stack>
                                            </Index.LocalizationProvider>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="admin-text-field-main from-input mb-0">
                                    <Index.Box className="input-box input-box-admin input-box-admin2">
                                        <Index.Box className="form-group transaction-fieldset">
                                            <Index.LocalizationProvider
                                                dateAdapter={Index.AdapterDayjs}
                                            >
                                                <Index.InputLabel
                                                    className="form-lable"
                                                    id="demo-simple-label"
                                                >
                                                    To
                                                </Index.InputLabel>
                                                <Index.Stack className=" date-picker-mui cus-datepicker">
                                                    {/* To Date */}
                                                    <Index.MobileDatePicker
                                                        className=" form-control datepicker"
                                                        format="DD-MM-YYYY"
                                                        value={
                                                            selectedToDate
                                                        }
                                                        minDate={selectedFromDate}
                                                        disableFuture
                                                        onChange={(newValue) => setSelectedToDate(newValue)}
                                                    />
                                                    {selectedToDate ? (
                                                        <Index.IconButton
                                                            className="clear-icon"
                                                            onClick={handleClearToDate}
                                                        >
                                                            <Index.ClearIcon className="icon-clear" />
                                                        </Index.IconButton>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Index.Stack>
                                            </Index.LocalizationProvider>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="user-search-main contest-winner-list-search">
                                    <Index.Box className="user-search-box">
                                        <Index.Box className="form-group">
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control"
                                                placeholder="Search"
                                                name="search"
                                                value={search}
                                                onChange={(e) => {
                                                    const newValue = e.target.value
                                                        .replace(/^\s+/, "")
                                                        .replace(/\s\s+/g, " ");
                                                    setCurrentPage(1);
                                                    setSearch(newValue);
                                                }}
                                            />
                                            <span className="search-icon-box">
                                                <img src={Index.Svg.search} className="search-icon" />
                                            </span>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>

                        <Index.Box className="admin-dashboard-list-row">
                            <Index.Box sx={{ width: 1 }} className="grid-main">
                                <Index.Box
                                    display="grid"
                                    className="display-row-userlist"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                >
                                    <Index.Box
                                        gridColumn={{
                                            xs: "span 12",
                                            sm: "span 12",
                                            md: "span 12",
                                            lg: "span 12",
                                        }}
                                        className="grid-column"
                                    >
                                        <Index.Box className="admin-dash-box">
                                            <Index.Box className="user-feedback-table-main page-table-main cus-contest-table ">
                                                <Index.TableContainer
                                                    component={Index.Paper}
                                                    className="table-container"
                                                >
                                                    <Index.Table
                                                        sx={{ minWidth: 650 }}
                                                        aria-label="simple table"
                                                        className="table"
                                                    >
                                                        <Index.TableHead className="table-head cus-table-head">
                                                            <Index.TableRow className="table-row">
                                                                <Index.TableCell
                                                                    component="th"
                                                                    variant="th"
                                                                    className="table-th"
                                                                    align="center"
                                                                >
                                                                    S.No
                                                                </Index.TableCell>
                                                                <Index.TableCell
                                                                    component="th"
                                                                    variant="th"
                                                                    className="table-th"
                                                                    align="center"
                                                                >
                                                                    Contest ID
                                                                </Index.TableCell>
                                                                <Index.TableCell
                                                                    component="th"
                                                                    variant="th"
                                                                    className="table-th"
                                                                    align="center"
                                                                >
                                                                    Contest Name
                                                                </Index.TableCell>
                                                                <Index.TableCell
                                                                    component="th"
                                                                    variant="th"
                                                                    className="table-th"
                                                                    align="center"
                                                                >
                                                                    Category
                                                                </Index.TableCell>
                                                                <Index.TableCell
                                                                    component="th"
                                                                    variant="th"
                                                                    className="table-th"
                                                                    align="center"
                                                                >
                                                                    Username
                                                                </Index.TableCell>


                                                                <Index.TableCell
                                                                    component="th"
                                                                    variant="th"
                                                                    className="table-th"
                                                                    align="center"
                                                                >
                                                                    <Index.Box className="drop-downup-arrow-main">
                                                                        Contest Start{" "}
                                                                    </Index.Box>
                                                                </Index.TableCell>

                                                                <Index.TableCell
                                                                    component="th"
                                                                    variant="th"
                                                                    className="table-th"
                                                                    align="center"
                                                                >
                                                                    Contest End{" "}
                                                                </Index.TableCell>
                                                                <Index.TableCell
                                                                    component="th"
                                                                    variant="th"
                                                                    className="table-th"
                                                                    align="center"
                                                                >
                                                                    Contest Result{" "}
                                                                </Index.TableCell>
                                                                <Index.TableCell
                                                                    component="th"
                                                                    variant="th"
                                                                    className="table-th"
                                                                    align="center"
                                                                >
                                                                    Participants Count
                                                                </Index.TableCell>
                                                                <Index.TableCell
                                                                    component="th"
                                                                    variant="th"
                                                                    className="table-th"
                                                                    align="center"
                                                                >
                                                                    Rank
                                                                </Index.TableCell>
                                                                <Index.TableCell
                                                                    component="th"
                                                                    variant="th"
                                                                    className="table-th"
                                                                    align="center"
                                                                >
                                                                    Prize
                                                                </Index.TableCell>

                                                            </Index.TableRow>
                                                        </Index.TableHead>
                                                        <Index.TableBody className="table-body">
                                                            {!loader ? (
                                                                contestList?.length > 0 ? (
                                                                    contestList?.map((row, index) => (
                                                                        <Index.TableRow
                                                                            key={row._id}
                                                                            sx={{
                                                                                "&:last-child td, &:last-child th": {
                                                                                    border: 0,
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Index.TableCell
                                                                                component="td"
                                                                                variant="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                                align="center"
                                                                            >
                                                                              
                                                                                {(currentPage - 1) * 10 + index + 1}
                                                                            </Index.TableCell>
                                                                            <Index.TableCell
                                                                                component="td"
                                                                                variant="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                                align="center"
                                                                            >
                                                                                {row?.contestId?.contestID}
                                                                            </Index.TableCell>
                                                                            <Index.TableCell
                                                                                component="td"
                                                                                variant="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                                align="center"
                                                                            // title= {row?.feedBackData && row?.feedBackData?.length>0 ? row?.feedBackData[row?.feedBackData.length-1].message:"-"}
                                                                            >

                                                                                {row?.contestId?.contestName
                                                                                    ? row?.contestId?.contestName
                                                                                    : "-"}
                                                                            </Index.TableCell>
                                                                            <Index.TableCell
                                                                                component="td"
                                                                                variant="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                                align="center"
                                                                            >


                                                                                {row?.contestId?.category ? row?.contestId?.category : "-"}
                                                                            </Index.TableCell>
                                                                            <Index.TableCell
                                                                                component="td"
                                                                                variant="td"
                                                                                scope="row"
                                                                                className="table-td "
                                                                                align="center"
                                                                            >

                                                                                <span className="cus-user-name-navigate"
                                                                                    onClick={() => {
                                                                                        navigate('/dashboard/user-view',
                                                                                            {
                                                                                                state: {
                                                                                                    data: row?.userId?._id,
                                                                                                    pathName: location.pathname
                                                                                                },
                                                                                            }
                                                                                        )
                                                                                    }}
                                                                                > {row?.userId?.userName ? row?.userId?.userName : "-"}</span>
                                                                            </Index.TableCell>

                                                                            <Index.TableCell
                                                                                component="td"
                                                                                variant="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                                align="center"
                                                                            >
                                                                                {row?.contestId?.startDate ? formatDate(row?.contestId?.startDate) + '  ' : ''}
                                                                                {row?.contestId?.startEntryTime ? moment(row?.contestId?.startEntryTime, 'HH:mm:ss').format('h:mm:ss A') : ''}
                                                                            </Index.TableCell>
                                                                            <Index.TableCell
                                                                                component="td"
                                                                                variant="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                                align="center"
                                                                            >
                                                                                {row?.contestId?.endDate ? formatDate(row?.contestId?.endDate) + ' ' : ''}
                                                                                {row?.contestId?.endEntryTime ? moment(row?.contestId?.endEntryTime, 'HH:mm:ss').format('h:mm:ss A') : ''}
                                                                            </Index.TableCell>
                                                                            <Index.TableCell
                                                                                component="td"
                                                                                variant="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                                align="center"
                                                                            >
                                                                                {row?.contestId?.resultDate ? formatDate(row?.contestId?.resultDate) + ' ' : ''}

                                                                                {row?.contestId?.resultTime ? moment(row?.contestId?.resultTime, 'HH:mm:ss').format('h:mm:ss A') : ''}
                                                                            </Index.TableCell>
                                                                            <Index.TableCell
                                                                                component="td"
                                                                                variant="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                                align="center"
                                                                            >

                                                                                {/* {row?.contestId?.spots ? row?.contestId?.spots?.length : "-"} */}
                                                                                {row?.contestId?.spots
                                                                                    ? Number.isInteger(row?.contestId?.spots?.length)
                                                                                        ? numeral(row?.contestId?.spots?.length).format('0')
                                                                                        : numeral(row?.contestId?.spots?.length).format('0.000a')
                                                                                    : 0}
                                                                            </Index.TableCell>

                                                                            <Index.TableCell
                                                                                component="td"
                                                                                variant="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                                align="center"
                                                                            >

                                                                                {row?.Rank ? row?.Rank : "-"}
                                                                            </Index.TableCell>
                                                                            <Index.TableCell
                                                                                component="td"
                                                                                variant="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                                align="center"
                                                                            >

                                                                                {/* {row?.coin ? row?.coin : "-"} */}
                                                                                {row?.coin
                                                                                    ?  parseFloat(row?.coin?.toFixed(4)): 0}
                                                                            </Index.TableCell>

                                                                            {(rolePermission &&
                                                                                rolePermission?.roleType?.rolePermission?.includes(
                                                                                    "ContestWinnerList_view"
                                                                                )) ||
                                                                                (rolePermission &&
                                                                                    rolePermission?.roleType?.rolePermission?.includes(
                                                                                        "ContestWinnerList_edit"
                                                                                    )) ||
                                                                                (rolePermission &&
                                                                                    rolePermission?.isAdmin === true) ? (
                                                                                ''
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </Index.TableRow>
                                                                    ))
                                                                ) : (
                                                                    <PageIndex.RecordNotFound colSpan={11} />
                                                                )
                                                            ) : (
                                                                <PageIndex.TableLoader colSpan={11} />
                                                            )}
                                                        </Index.TableBody>
                                                    </Index.Table>
                                                </Index.TableContainer>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        {contestList.length ? (
                            <Index.Box className="pagination-main">
                                <Index.TablePagination
                rowsPerPageOptions={[
                  { label: "All", value: -1 },
                  10,
                  25,
                  50,
                  75,
                  100,
                ]}
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={currentPage - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
                variant="outlined"
                shape="rounded"
                className="pagination"
              />
                            </Index.Box>
                        ) : (
                            ""
                        )}
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </>
    );
};

export default ContestWinnerList;
