import React, { useEffect } from "react";
import Index from "../../../Index";
import { useState } from "react";
import PageIndex from "../../../PageIndex";
import { addEditContestData, getCurrencyListAction } from "../../../../redux/slices/adminService";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import moment from "moment";
import { addEditContestSchema } from "../../../../validation/Validation";
import { imageUrl } from "../../../../config/dataService";

const ReopenContest = () => {

  const [percentage, setPercentage] = useState(100)
  const [rankError, setRankError] = useState('')
  const [currencyList, setCurrencyList] = useState([])

  const currencyTypeData = [
    { id: "1", name: "Crypto" },
    { id: "2", name: "Fiat" },
  ];
  const roleTypeData = [
    { id: "1", name: "Popular" },
    { id: "2", name: "Random" },
  ];
  const SubCategoryListData = [
    { id: "1", name: "Reel", value: "Reel" },
    { id: "2", name: "Post", value: "Post" },
  ];
 
  //To convert Time from "Sat Feb 24 2024 17:00:00 GMT+0530 (India Standard Time)" to "17:00:00"

  function extractTimeFromDate(dateString) {
    const dateObject = new Date(dateString);
    const time = dateObject.toLocaleTimeString("en-IN", { hour12: false });
    return time;
  }

  //To convert Time from "Sat Feb 24 2024 17:00:00 GMT+0530 (India Standard Time)" to "17:00:00" 

  function extractTimeFromDate(dateString) {
    const dateObject = new Date(dateString);
    const time = dateObject.toLocaleTimeString('en-IN', { hour12: false });
    return time;
  }

  //To convert Time from "Sat Feb 24 2024 17:00:00 GMT+0530 (India Standard Time)" to "17:00:00" 

  function extractTimeFromDate(dateString) {
    const dateObject = new Date(dateString);
    const time = dateObject.toLocaleTimeString('en-IN', { hour12: false });
    return time;
  }

  const navigate = PageIndex.useNavigate();
  const { state } = PageIndex.useLocation();

  const [loader, setLoader] = useState(false);
  const initialValues = {
    contestFees: state ? state?.contestFees : "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    category: state ? state?.category : "",
    subCategory: state ? state?.subCategory : "",
    contestName: state ? state?.contestName : "",
    spotNumber: state ? state?.spotNumber : "",
    limit: state ? state?.limit : "",
    adminComission: state ? state?.adminComission : "",
    description: state ? state?.description : "",
    winningPrices:
      state && Array.isArray(state.winningPrices)
        ? [...state.winningPrices]
        : [],
    banner: state ? state?.banner : "",
    isEdit: true,
    currency: state ? state?.currency : "",
    currencyType: state ? state?.fiatType? state?.fiatType?._id:state?.cryptoType?._id : "",

    bonus: state ? state?.bonus : "",
  };
 

  const handleAddField = (values, setFieldValue) => {
    const arr = [...values.winningPrices, { Rank: "", prize: "" }];
    setFieldValue("winningPrices", arr);
  };

  const handleRemoveField = (values, setFieldValue) => {
    const updatedWinningPrices = values.winningPrices.slice(0, -1);
    setFieldValue("winningPrices", updatedWinningPrices);
  };

  const handleFormSubmit = async (values) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("id", state?._id);
    formData.append("contestFees", values?.contestFees);
    formData.append(
      "startDate",
      moment(values?.startDate).format("YYYY/MM/DD")
    );
    formData.append("endDate", moment(values?.endDate).format("YYYY/MM/DD"));
    formData.append("startTime", extractTimeFromDate(values?.startTime));
    formData.append("endTime", extractTimeFromDate(values?.endTime));
    formData.append("category", values?.category);
    formData.append("subCategory", values?.subCategory);
    formData.append("currency", values?.currency);
    formData.append("description", values?.description);
    formData.append("contestName", values?.contestName);
    formData.append("spotNumber", values?.spotNumber);
    formData.append("limit", values?.limit);
    formData.append("adminComission", values?.adminComission);
    formData.append("winningPrices", JSON.stringify(values.winningPrices));
    formData.append("banner", values?.banner);
    formData.append("type", "Reopen");
    // formData.append("currencyType", values?.currencyType);
    if(values?.currency==="Fiat"){
      formData.append("fiatType", values?.currencyType);
      }else{
      formData.append("cryptoType", values?.currencyType);
      }
    formData.append("bonus", values?.bonus?values?.bonus:0);

    addEditContestData(formData).then((data) => {
      if (data?.status == 200) {
        setLoader(false);
        navigate("/dashboard/contest-list");
      } else {
        setTimeout(() => {
          setLoader(false);
        }, 6000);
      }
    });
  };

  const handleError = (rank, index, spotNumber, values) => {
    let isError = false

    const rankRange = values?.winningPrices?.reduce((total, { Rank, prize }) => {

      const [start, end] = Rank.split(' to ').map(Number);
      return total + (parseInt(end) - parseInt(start) + 1);

    }, 0);


    if (rankRange > spotNumber) {
      setRankError('Rank must be less than or equal to spot number.');
      isError = true
    }
    else {
      setRankError('');
      isError = false
    }
    return isError
  }

  const calculation = (values) => {

    const totalPrize = values?.winningPrices?.reduce((total, { Rank, prize }) => {
      if (prize && !isNaN(prize)) {
        const [start, end] = Rank.split(' to ').map(Number);
        const rankRange = end - (start==0?1:start) + 1;
        return total + (isNaN(rankRange) ? 0 : rankRange) * prize;
     
      }
      return total;
    }, 0);

    setPercentage(100 - totalPrize)

  };


  useEffect(() => {
    if (state?.winningPrices) {
      const totalPrize = state?.winningPrices?.reduce((total, { Rank, prize }) => {
        if (prize && !isNaN(prize)) {
          const [start, end] = Rank.split(' to ').map(Number);
          const rankRange = end - (start==0?1:start) + 1;
          return total + (isNaN(rankRange) ? 0 : rankRange) * prize;
        }
        return total;
      }, 0);

      setPercentage(100 - totalPrize)
    }
  }, [state?.winningPrices])


  const getcurrencyType = (data) => {
    getCurrencyListAction(data).then((res) => {

      setCurrencyList(res?.data)
    })
  }

useEffect(()=>{
  getcurrencyType(state?.currency)
},[]) 

  return (
    <>
      <Index.Box className="dashboard-content add-user-containt">
      <PageIndex.BackButton onClick={() => navigate("/dashboard/contest-list")} />
        <Index.Box className="barge-common-box">
          {/* <Index.Box className="page-border"> */}

          <Index.Typography
            className="admin-page-title cus-heading"
            component="h2"
            variant="h2"
          >
            Reopen Contest
          </Index.Typography>

          <PageIndex.Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={addEditContestSchema}
          >
            {({
              values,
              handleBlur,
              handleChange,
              errors,
              setFieldValue,
              touched,
            }) => (
              <PageIndex.Form>
                <Index.Box className="add-user-data-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row-add-user"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Contest Name
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              disabled
                              id="fullWidth"
                              className="form-control"
                              placeholder="Contest Name"
                              name="contestName"
                              value={values.contestName}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setFieldValue("contestName", newValue);
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.contestName
                                  ? touched?.contestName
                                  : undefined
                              )}
                              helperText={
                                touched?.contestName
                                  ? errors?.contestName
                                  : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input ">
                              <Index.FormHelperText className="form-lable">
                                Contest Fee{" "}
                                <span className="mandatory-asterisk">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="form-group contest-fee-currency">
                                <Index.Select
                                  className="form-control select-drop-list contest-currency"
                                  name="currency"
                                  value={values?.currency}
                                  // defaultValue={values?.currency ? values?.currency : "Crypto"}
                                  onChange={(e) => {
                                    setFieldValue("currency", e.target.value);
                                    getcurrencyType(e.target.value)
                                    setFieldValue("currencyType", '');

                                  }}
                                  displayEmpty
                                  inputProps={{
                                    "aria-label": "Without label",
                                  }}
                                  onBlur={handleBlur}
                                >
                                  <Index.MenuItem
                                    value=""
                                    // disabled
                                    className="menuitem"
                                  >
                                    Select currency type
                                  </Index.MenuItem>
                                  {currencyTypeData &&
                                    currencyTypeData?.map((val) => (
                                      <Index.MenuItem
                                        value={val?.name}
                                        key={val?.id}
                                        className="menuitem"
                                      >
                                        {val?.name}
                                      </Index.MenuItem>
                                    ))}
                                </Index.Select>
                                <Index.Select
                                  className="form-control select-drop-list contest-currency"
                                  name="currencyType"
                                  value={values?.currencyType}
                                  // defaultValue={values?.currency ? values?.currency : "Crypto"}
                                  onChange={(e) => {
                                    setFieldValue("currencyType", e.target.value);
                                  }}
                                  displayEmpty
                                  inputProps={{
                                    "aria-label": "Without label",
                                  }}
                                  onBlur={handleBlur}
                                >
                                  <Index.MenuItem
                                    value=""
                                    // disabled
                                    className="menuitem"
                                  >
                                    Select currency
                                  </Index.MenuItem>
                                  {currencyList &&
                                    currencyList?.map((val) => (
                                      <Index.MenuItem
                                        value={val?._id}
                                        key={val?._id}
                                        className="menuitem"
                                      >
                                        {values.currency != 'Crypto' ? val?.coins : val?.tokenName}
                                      </Index.MenuItem>
                                    ))}
                                </Index.Select>
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  type="text"
                                  className="form-control contest-fee"
                                  placeholder="Contest Fee"
                                  name="contestFees"
                                  value={values.contestFees}
                                  onChange={(e) => {
                                    const newValue = e.target.value.replace(
                                      /\D+/g,
                                      ""
                                    );
                                    if (newValue.length <= 10) {
                                      setFieldValue("contestFees", newValue);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                // error={Boolean(
                                //   errors?.contestFees ? touched?.contestFees : undefined
                                // )}
                                // helperText={
                                //   touched?.contestFees ? errors?.contestFees : undefined
                                // }
                                />
                              </Index.Box>

                              {!errors.currencyType && !errors.currency && errors.contestFees && touched.contestFees && (
                                <p className="error-text">{errors.contestFees}</p>
                              )}
                              {!errors.currency && touched.currencyType && errors.currencyType && (
                                <p className="error-text">{errors.currencyType}</p>
                              )}
                              {touched.currency && errors.currency && (
                                <p className="error-text">{errors.currency}</p>
                              )}





                            </Index.Box>
                          </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Start Date{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group custom-datepicker">
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              className="date-adapter-main"
                            >
                              <DemoContainer
                                components={["DatePicker", "DatePicker"]}
                                className="date-adapter-container"
                              >
                                <MobileDatePicker
                                  className="date-adapter-picker"
                                  name="startDate"
                                  value={dayjs(values?.startDate)}
                                  onChange={(e) => {
                                    const selectedDate = e?.$d;
                                   
                                    if (
                                      dayjs(selectedDate).isAfter(
                                        dayjs(values?.endDate),
                                        "day"
                                      )
                                    ) {
                                      setFieldValue("endDate", null);
                                      setFieldValue("endTime", null);
                                    }
                                    setFieldValue("startDate", e?.$d);
                                    setFieldValue("minDate", e?.$d);
                                  }}
                                  // maxDate={dayjs(values?.endDate) || undefined}
                                  format="DD/MM/YYYY"
                                  disablePast

                                  onBlur={handleBlur}

                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {errors.startDate && touched.startDate && (
                              <p className="error-text">{errors.startDate}</p>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Start Time{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group custom-datepicker">
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              className="date-adapter-main"
                            >
                              <DemoContainer
                                components={["DatePicker", "DatePicker"]}
                                className="date-adapter-container"
                              >
                                <MobileTimePicker
                                  className="date-adapter-picker"
                                  name="startTime"
                                  // value={
                                  //   values?.startTime
                                  //     ? dayjs(values?.startTime)
                                  //     : null
                                  // }
                                  onChange={(selectedTime) => {
                                    const newStartTime = selectedTime.$d;
                                    if (
                                      dayjs(values?.endDate).isSame(
                                        values?.startDate,
                                        "day"
                                      ) &&
                                      dayjs(newStartTime).isAfter(
                                        dayjs(values?.endTime),
                                        "minute"
                                      )
                                    ) {
                                      setFieldValue("endTime", null);
                                    }
                                    setFieldValue("startTime", newStartTime);
                                  }}
                                  minTime={
                                    dayjs(values?.startDate).isSame(
                                      dayjs(),
                                      "day"
                                    )
                                      ? dayjs().startOf("minute")
                                      : dayjs().startOf("day")
                                  }
                                  // disablePast
                                  ampm={false}
                                  format="HH:mm"
                                  onBlur={handleBlur}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {errors.startTime && touched.startTime && (
                              <p className="error-text">{errors.startTime}</p>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            End Date{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group custom-datepicker">
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              className="date-adapter-main"
                            >
                              <DemoContainer
                                components={["DatePicker", "DatePicker"]}
                                className="date-adapter-container"
                              >
                                <MobileDatePicker
                                  className="date-adapter-picker"
                                  name="endDate"
                                  value={dayjs(values?.endDate)}
                                  onChange={(e) => {
                                    const newEndDate = e?.$d;

                                    if (
                                      dayjs(values?.startDate).isSame(
                                        newEndDate,
                                        "day"
                                      )
                                    ) {
                                      setFieldValue("endTime", null);
                                    }

                                    setFieldValue("endDate", newEndDate);
                                  }}
                                  format="DD/MM/YYYY"
                                  minDate={dayjs(values?.minDate) || undefined}
                                  onBlur={handleBlur}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {errors.endDate && touched.endDate && (
                              <p className="error-text">{errors.endDate}</p>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            End Time{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group custom-datepicker">
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              className="date-adapter-main"
                            >
                              <DemoContainer
                                components={["DatePicker", "DatePicker"]}
                                className="date-adapter-container"
                              >
                                <MobileTimePicker
                                  className="date-adapter-picker"
                                  name="endTime"
                                  value={
                                    values?.endTime
                                      ? dayjs(values?.endTime)
                                      : null
                                  }
                                  onChange={(selectedTime) => {
                                    const newEndTime = selectedTime?.$d;
                                    if (
                                      dayjs(values?.startDate).isSame(
                                        dayjs(values?.endDate),
                                        "day"
                                      ) &&
                                      dayjs(values?.startTime).isAfter(
                                        dayjs(newEndTime),
                                        "minute"
                                      )
                                    ) {
                                      setFieldValue(
                                        "endTime",
                                        values?.startTime
                                      );
                                    } else {
                                      setFieldValue("endTime", newEndTime);
                                    }
                                  }}
                                  minTime={
                                    dayjs(values?.endDate).isSame(
                                      dayjs(values?.startDate),
                                      "day"
                                    )
                                      ? dayjs(values?.startTime).startOf(
                                        "minute"
                                      )
                                      : dayjs().startOf("day")
                                  }
                                  // minTime={dayjs(values?.startTime)}
                                  ampm={false}
                                  format="HH:mm"
                                  onBlur={handleBlur}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {errors.endTime && touched.endTime && (
                              <p className="error-text">{errors.endTime}</p>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Spot Number  <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              // disabled
                              id="fullWidth"
                              type="text"
                              className="form-control"
                              placeholder="Spot Number"
                              name="spotNumber"
                              value={values.spotNumber}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\D+/g,
                                  ""
                                );
                                if (newValue.length <= 10) {
                                  setFieldValue("spotNumber", newValue);
                                }
                              }}
                              error={Boolean(
                                errors?.spotNumber
                                  ? touched?.spotNumber
                                  : undefined
                              )}
                              helperText={
                                touched?.spotNumber
                                  ? errors?.spotNumber
                                  : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Limit(min limit)  <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              type="text"
                              className="form-control"
                              placeholder="Limit"
                              name="limit"
                              value={values.limit}
                              // onChange={(e) => {
                              //   setFieldValue(
                              //     "limit",
                              //     e.target.value.slice(0, 10)
                              //   );
                              // }}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\D+/g,
                                  ""
                                );
                                if (newValue.length <= 10) {
                                  setFieldValue("limit", newValue);
                                }
                              }}
                              // helperText={errors.limit}
                              // error={Boolean(errors.limit)}
                              error={Boolean(
                                errors?.limit ? touched?.limit : undefined
                              )}
                              helperText={
                                touched?.limit ? errors?.limit : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Admin Commission
                          </Index.FormHelperText>
                          <Index.Box className="form-group ">
                            <Index.TextField
                              fullWidth
                              disabled
                              id="fullWidth"
                              type="text"
                              className="form-control"
                              placeholder="Admin Commission"
                              name="adminComission"
                              value={values.adminComission}
                              // onChange={(e) => {
                              //   setFieldValue(
                              //     "adminComission",
                              //     e.target.value.slice(0, 10)
                              //   );
                              // }}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\D+/g,
                                  ""
                                );
                                if (newValue.length <= 10) {
                                  setFieldValue("adminComission", newValue);
                                }
                              }}
                              // helperText={errors.adminComission}
                              // error={Boolean(errors.adminComission)}
                              error={Boolean(
                                errors?.adminComission
                                  ? touched?.adminComission
                                  : undefined
                              )}
                              helperText={
                                touched?.adminComission
                                  ? errors?.adminComission
                                  : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Category
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.FormControl className="radio-main">
                              <Index.Select
                                disabled
                                className="form-control select-drop-list"
                                name="category"
                                value={values?.category}
                                defaultValue={values ? values?.category : ""}
                                onChange={(e) => {
                                  setFieldValue("category", e.target.value);
                                }}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                              >
                                <Index.MenuItem
                                  value=""
                                  // disabled
                                  className="menuitem"
                                >
                                  Select category
                                </Index.MenuItem>
                                {roleTypeData &&
                                  roleTypeData?.map((val) => (
                                    <Index.MenuItem
                                      value={val?.name}
                                      key={val?.id}
                                      className="menuitem"
                                    >
                                      {val?.name}
                                    </Index.MenuItem>
                                  ))}
                              </Index.Select>
                            </Index.FormControl>
                            {errors.category && touched.category && (
                              <p className="error-text">{errors.category}</p>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Sub-Category
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.FormControl className="radio-main">
                              <Index.Select
                                disabled
                                className="form-control select-drop-list"
                                name="subCategory"
                                value={values?.subCategory}
                                defaultValue={values ? values?.subCategory : ""}
                                onChange={(e) => {
                                  setFieldValue("subCategory", e.target.value);
                                }}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                              >
                                <Index.MenuItem
                                  value=""
                                  // disabled
                                  className="menuitem"
                                >
                                  Select Sub-Category
                                </Index.MenuItem>
                                {SubCategoryListData &&
                                  SubCategoryListData?.map((val) => (
                                    <Index.MenuItem
                                      value={val?.value}
                                      key={val?.id}
                                      className="menuitem"
                                    >
                                      {val?.name}
                                    </Index.MenuItem>
                                  ))}
                              </Index.Select>
                            </Index.FormControl>
                            {errors.subCategory && touched.subCategory && (
                              <p className="error-text">{errors.subCategory}</p>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Description <span className="mandatory-asterisk">
                              *
                            </span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextareaAutosizeed
                              fullWidth
                              id=""
                              className="form-control contest-text-autosize"
                              placeholder="Description"
                              name="description"
                              value={values.description}
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/^\s+/, "")
                                  .replace(/\s\s+/g, " ");
                                setFieldValue("description", newValue);
                              }}
                              onBlur={handleBlur}
                            // error={Boolean(
                            //   errors?.description
                            //     ? touched?.description
                            //     : undefined
                            // )}
                            // helperText={
                            //   touched?.description
                            //     ? errors?.description
                            //     : undefined
                            // }
                            />
                          </Index.Box>
                          {errors?.description &&
                            touched?.description && (
                              <p className="custom-error-tag">
                                {errors?.description}
                              </p>
                            )}
                        </Index.Box>

                      </Index.Box>
                      <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                              Bonus{" "}
                                {/* <span className="mandatory-asterisk">*</span> */}
                              </Index.FormHelperText>
                              <Index.Box className="form-group ">
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  type="text"
                                  className="form-control"
                                  placeholder="Bonus"
                                  name="bonus"
                                  value={values.bonus}
                                  onChange={(e) => {
                                    const newValue = e.target.value.replace(
                                      /\D+/g,
                                      ""
                                    );
                                    if (newValue.length <= 10) {
                                      setFieldValue("bonus", newValue);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  // error={Boolean(
                                  //   errors?.bonus
                                  //     ? touched?.bonus
                                  //     : undefined
                                  // )}
                                  // helperText={
                                  //   touched?.bonus
                                  //     ? errors?.bonus
                                  //     : undefined
                                  // }
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          {values?.winningPrices?.map((field, index) => (
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column "
                            >
                              <Index.Box
                                // gridColumn={{ xs: "span 8" }}
                                className="grid-column rank_dis_input "
                                key={index}
                              >
                                <Index.Box className="rank_distribution_wrap">
                                  <Index.Box className="input-box add-user-input rank_dis_input  price-box-main">
                                    <Index.Box className="form-group">
                                      <Index.FormHelperText className="form-lable">
                                        Rank
                                        <span className="mandatory-asterisk">
                                          *
                                        </span>
                                      </Index.FormHelperText>

                                      <Index.TextField
                                        fullWidth
                                        id={`rankInput-${index}`}
                                        type="text"
                                        className="form-control rank-input"
                                        placeholder="Rank (1 to 5)"
                                        name={`winningPrices[${index}].Rank`}
                                        value={
                                          values?.winningPrices[index]?.Rank
                                        }
                                        onChange={(e) => {
                                          const rank = e.target.value
                                            .replace(/^\s+/, "")
                                            .replace(/\s\s+/g, " ");
                                          const updatedValues = [
                                            ...values.winningPrices,
                                          ];
                                          updatedValues[index].Rank = rank;

                                          calculation(
                                            values,
                                            values?.winningPrices[index]?.Rank,
                                            values?.winningPrices[index]?.prize,
                                            setFieldValue,
                                            index
                                          );
                                          setFieldValue(
                                            `winningPrices[${index}].Rank`,
                                            rank
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        error={Boolean(
                                          errors?.winningPrices?.[index]?.Rank
                                            ? touched?.winningPrices?.[index]
                                              ?.Rank
                                            : undefined
                                        )}
                                        helperText={
                                          touched?.winningPrices?.[index]?.Rank
                                            ? errors?.winningPrices?.[index]
                                              ?.Rank
                                            : undefined
                                        }
                                      />
                                    </Index.Box>
                                    <Index.Box className="form-group">
                                      <Index.Box className="price-lable-box">
                                        <Index.FormHelperText className="form-lable">
                                          Price Distribution (%){" "}
                                          <span className="mandatory-asterisk">
                                            *
                                          </span>
                                        </Index.FormHelperText>
                                        {values?.winningPrices?.length - 1 ==
                                          index && (
                                            <Index.FormHelperText className="remains-text-box">
                                              {percentage < 0 ||
                                                percentage > 100 ? (
                                                <p className="remains-text">
                                                  {" "}
                                                  {`Remaining ${0}%`}
                                                </p>
                                              ) : (
                                                `Remaining ${percentage.toFixed(
                                                  2
                                                )}%`
                                              )}
                                            </Index.FormHelperText>
                                          )}
                                      </Index.Box>

                                      <Index.TextField
                                        fullWidth
                                        id={`fullWidth-${index}`}
                                        type="text"
                                        className="form-control percentage-input"
                                        placeholder={`Price Distribution`}
                                        name={`winningPrices[${index}].prize`}
                                        value={
                                          values?.winningPrices[index]?.prize ||
                                          ""
                                        }
                                        onChange={(e) => {
                                          const newValue = e.target.value
                                            .replace(/[^\d.]+/g, "")
                                            .replace(
                                              /^(\d*\.\d{0,2}).*$/,
                                              "$1"
                                            );

                                          const updatedValues = [
                                            ...values.winningPrices,
                                          ];
                                          updatedValues[index].prize =
                                            parseFloat(newValue, 10);

                                          setFieldValue(
                                            `winningPrices[${index}].prize`,
                                            newValue
                                          );

                                          calculation(
                                            values,
                                            values?.winningPrices[index]?.Rank,
                                            values?.winningPrices[index]?.prize,
                                            setFieldValue,
                                            index
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        error={Boolean(
                                          errors?.winningPrices?.[index]?.prize
                                            ? touched?.winningPrices?.[index]
                                              ?.prize
                                            : undefined
                                        )}
                                        helperText={
                                          touched?.winningPrices?.[index]
                                            ?.prize ? (
                                            percentage < 0 ||
                                              percentage > 100 ? (
                                              <p className="error-text">
                                                Price must be less than or equal
                                                to remaining %
                                              </p>
                                            ) : (
                                              errors?.winningPrices?.[index]
                                                ?.prize
                                            )
                                          ) : undefined
                                        }
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                  {index === values?.winningPrices.length - 1 && <Index.Box className="add-remove-btn-box">
                                    <Index.Tooltip title="Add text-box">
                                      <Index.IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                        onClick={() =>
                                          handleAddField(values, setFieldValue)
                                        }
                                      >
                                        <Index.AddBoxIcon />
                                      </Index.IconButton>
                                    </Index.Tooltip>

                                    {values?.winningPrices?.length !== 1 && (
                                      <Index.Tooltip title="Remove text-box">
                                        <Index.IconButton
                                          color="primary"
                                          aria-label="upload picture"
                                          component="label"
                                          onClick={() =>
                                            handleRemoveField(values, setFieldValue)
                                          }
                                        >
                                          <Index.IndeterminateCheckBoxIcon />
                                        </Index.IconButton>
                                      </Index.Tooltip>
                                    )}
                                  </Index.Box>}
                                </Index.Box>

                              </Index.Box>

                            </Index.Box>

                          ))}
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Banner Image
                          </Index.FormHelperText>
                          <Index.Box className="profile-header cus-banner-box">
                            <Index.Box className="profile-input-box">
                              <label htmlFor="btn-upload">
                                <img
                                  src={
                                    values?.banner
                                      ?
                                      values?.banner instanceof Blob
                                        ? URL.createObjectURL(values.banner)
                                        :
                                        `${imageUrl}${values?.banner}`
                                      : PageIndex.Png.cameraImage
                                  }
                                  alt="banner img"
                                  className={
                                    values?.banner
                                      ? "banner-img"
                                      : "profile-img mb-15"
                                  }
                                />

                                <input
                                  disabled
                                  id="btn-upload"
                                  name="banner"
                                  className="profile-upload-btn"
                                  type="file"
                                  accept="image/*"
                                  // hidden
                                  onChange={(e) => {
                                    setFieldValue("banner", e.target.files[0]);
                                  }}
                                />
                                <Index.Box className="profile-edit-main">
                                  <Index.Box className="edit-div" title="Edit">
                                    {" "}
                                    <img
                                      className="profile-edit-img"
                                      src={PageIndex.Png.editLogo}
                                      alt=""
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </label>
                              {errors.banner && touched.banner && (
                                <p className="error-text">{errors.banner}</p>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="user-btn-flex">
                    <Index.Box className="save-btn-main border-btn-main">
                      <Index.Button
                        className="save-user-btn border-btn"
                        type="submit"
                        disabled={loader}
                      >
                        {loader ? (
                          <PageIndex.ButtonLoader
                            color="white"
                            size={14}
                            loading={loader}
                          />
                        ) : (
                          <>
                            <img
                              src={Index.Svg.save}
                              className="user-save-icon"
                              alt="Save Icon"
                            />
                            Save
                          </>
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </PageIndex.Form>
            )}
          </PageIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default ReopenContest;
