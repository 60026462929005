import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import UserCommentReportList from "./UserCommentList";
import UserPostReportList from "./UserPostReportList";
import UserProfileportList from "./UserProfileReportList";
import UserReelReport from "./UserReelReport";
import { autoLogout } from "../../../../redux/slices/adminService";
import {
  logout,
  updateRolePermission,
} from "../../../../redux/slices/AdminSlice";

const UserReportList = () => {
  const postKeys = PageIndex.useLocation();
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const { token } = PageIndex.useSelector((state) => state.admin);
  const queryParams = new URLSearchParams(window.location.search);
  const stateParam = queryParams.get("state");
  const state = JSON.parse(decodeURIComponent(stateParam));

  const [today, setToday] = useState(state?.today ? state?.today : "overview");
  const [value, setValue] = useState(
    postKeys?.state?.postKey ? postKeys?.state?.postKey : 0
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    autoLogout(token).then((res) => {
      if (res?.data?.status == 200) {
        dispatch(updateRolePermission(res?.data?.data));
      } else if (res?.response?.data?.status == 401) {
        dispatch(logout());
        navigate("/");
      }
    });
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <Index.Box className="table-wrape">
      <Index.Box className="external-tab-box">
        <Index.Box className="admin-page-title-main">
          <Index.Typography
            className="admin-page-title tittle-like-costam"
            component="h2"
            variant="h2"
          >
            User Report List
          </Index.Typography>
        </Index.Box>
        <Index.Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="admin-tabs-main"
        >
          <Index.Tab label="Post" {...a11yProps(0)} className="admin-tab" />
          <Index.Tab label="Profile" {...a11yProps(1)} className="admin-tab" />
          <Index.Tab label="Reel" {...a11yProps(2)} className="admin-tab" />
        </Index.Tabs>
      </Index.Box>
      {value == 0 ? (
        <>
          <UserPostReportList
            today={today}
            btnValue={value}
            setBtnValue={setValue}
          />
        </>
      ) : value == 1 ? (
        <UserProfileportList
          today={today}
          btnValue={value}
          setBtnValue={setValue}
        />
      ) : (
        <UserReelReport today={today} btnValue={value} setBtnValue={setValue} />
      )}
    </Index.Box>
  );
};
export default UserReportList;
